* {
  line-height: normal !important;
}

.p_container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.p_container:last-child {
  margin-bottom: 0;
}

.p_container img,
table {
  max-height: 100px;
}

.trans_item_detail {
  width: 100%;
}

.trans_item_detail td {
  vertical-align: top;
  text-transform: none;
}

.smallText {
  font-size: 0.9rem;
}

.note {
  font-size: 0.8rem;
}

._note {
  color: grey;
}

@media screen and (max-width: 850px) {
  .trans_item_detail {
    width: max-content;
  }
}
