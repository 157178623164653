.container {
  position: relative;
  --padding: 0.3rem;
}

.input_container {
  display: flex;
  justify-content: space-between;
  min-width: 100px;
  border-bottom: 1px solid lightgrey;
}

.input_container input {
  cursor: pointer !important;
  border: none;
  outline: none !important;
  padding: 0.2rem 0.5rem;
  width: 190px;
}

.input_container i {
  cursor: pointer !important;
}

.container_list {
  position: absolute;
  background-color: white;
  border-radius: 0.5rem;
  margin-top: 0.5rem;
  padding: 0 var(--padding);

  width: max-content;
  right: 1;
  z-index: 2;
}

.down,
.up {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.2s ease-in-out;
  -moz-transition: max-height 0.2s ease-in-out;
  -o-transition: max-height 0.2s ease-in-out;
  transition: max-height 0.2s ease-in-out;
}

.down {
  max-height: 500px;
  outline: 1px solid lightgrey;
  box-shadow: 2px 2px 2px lightgrey;
}

.item {
  padding: 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;

  display: flex;
  gap: 0.5rem;
  font-weight: 600;
}

.item i {
  color: var(--main_color);
}

.item:first-child {
  margin-top: var(--padding);
}

.item:last-child {
  margin-bottom: var(--padding);
}
