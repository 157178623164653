.container {
  height: 80vh;
  max-height: 80vh;
  padding: 0.5rem;
  display: flex;
  /*added*/
  flex-direction: column;
  /*added*/
  overflow-y: auto;
}

.container::-webkit-scrollbar {
  width: 0.3rem;
}

.container::-webkit-scrollbar-track {
  border: 1px solid #ffffff;
  border-radius: 0 0.5rem 0.5rem 0;
  background-color: #ffffff;
}

.container::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--main_color);
}

/* date */
.right_position {
  margin-left: auto;
  margin-right: 0;
}

.out_date_container {
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  margin-top: 0.5rem;
}

.out_date_container span {
  padding: 0.2rem 0.5rem;
  font-weight: 600;
}

.date_container {
  display: flex;
  align-items: center;
  /* justify-content: flex-end; */
}

.date_container>div>div {
  z-index: 2;
}

.date_container span {
  margin: 0 0.4rem;
  font-weight: 600;
}

.date_container input {
  width: 120px;
  border-radius: 0.3rem;
  border: none;
  border-bottom: 1px solid lightgray;
  padding: 0.2rem 0.4rem;
  font-weight: 600;
  text-align: center;
}

.date_container input:focus-visible {
  outline: none;
}

/* btn */
.btn_container {
  display: flex;
  padding: 0.5rem;
  /* margin: 0.5rem 0; */
  gap: 0.5rem;
  justify-content: flex-end;
}

.btn_container button {
  width: fit-content;
}

/* filter */
.filter_container {
  display: flex;
  gap: 0.5rem;
  margin: 0.5rem 0;
  padding: 0 0.5rem;
}

/* table */
.table_container {
  margin-top: 1rem;
  height: 100%;
}

.table_container i {
  cursor: pointer;
  color: var(--main_color);
  font-size: 1.2rem;
}

.table_container>div>div {
  min-height: 300px;
}

.link {
  color: var(--main_color) !important;
  text-decoration: underline;
  cursor: pointer;
}

.icon {
  margin: 0 0.2rem;
}

/* popup */
.dtl_account {
  text-transform: capitalize;
  padding-bottom: 0.5rem;
  font-weight: 600;
}

@media screen and (max-width: 400px) {
  .btn_container {
    flex-direction: column;
  }

  .btn_container button {
    width: 100%;
  }

  .filter_container {
    flex-direction: column;
    gap: 0.5rem;
  }
}


/* popup */
.list_container {
  max-height: 400px;
  margin-top: 0.5rem;
  padding: 0 0.5rem;
}

.card_container {
  border: 1px solid lightgrey;
  padding: 0.2rem 0.5rem;
  border-radius: 0.5rem;
}

.item_btn {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.detail_container {
  font-weight: 600;
  max-height: 0;
  overflow-y: hidden;
  width: 100%;
}

.detail_container,
.show {
  -webkit-transition: max-height 0.5s ease-in-out;
  -moz-transition: max-height 0.5s ease-in-out;
  -o-transition: max-height 0.5s ease-in-out;
  transition: max-height 0.5s ease-in-out;
}

.show {
  overflow-y: scroll;
  max-height: 400px;
}

.show::-webkit-scrollbar {
  width: 0.3rem;
}

.show::-webkit-scrollbar-track {
  border: 1px solid #ffffff;
  border-radius: 0 0.5rem 0.5rem 0;
  background-color: #ffffff;
}

.show::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--main_color);
}