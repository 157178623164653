.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.container input {
  display: none;
}

.btn,
.img_btn,
.btn_input {
  padding: 0.2rem 0.5rem;
  border-radius: 0.5rem;
}

.btn {
  cursor: pointer;
  width: 100%;
  max-width: 150px;
  color: white;
  background-color: var(--main_color);

  font-weight: 600;
  text-align: center;
}

.img_btn img {
  max-height: 100px;
  max-width: 100%;
}

.img_btn_container {
  position: relative;
}

.img_btn_container i {
  font-size: 1.5rem;
  color: red;
  position: absolute;
  cursor: pointer;
  right: 5px;

  background-color: white;
  border-radius: 0.5rem;
}

.btn_input {
  font-size: 1.1rem;
  border: 1px dashed black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  width: 100%;
  height: 100px;
  text-align: center;
}

.round {
  font-size: 3rem;
  border-radius: 50%;
  width: 100px;
}
