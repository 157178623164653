.container {
	width: 100%;
	display: flex;
	flex-wrap: wrap;

	background-color: var(--main_color);
	color: white;
	/* position: sticky;
	bottom: 0;
	z-index: 30; */
}

.part_container {
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	padding: 1rem;
}

.part_container a {
	color: white !important;
	text-decoration: none;
}

.part_container i {
	margin-right: 0.5rem;
}

.part_title {
	width: 100%;
	font-size: larger;
	font-weight: 600;
}
