.container {
  height: 100%;
  padding: 0.5rem 0.4rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.container::-webkit-scrollbar,
.input_textarea textarea::-webkit-scrollbar {
  width: 0.3rem;
}

.container::-webkit-scrollbar-track,
.input_textarea textarea::-webkit-scrollbar-track {
  border: 1px solid #ffffff;
  border-radius: 0 0.5rem 0.5rem 0;
  background-color: #ffffff;
}

.container::-webkit-scrollbar-thumb,
.input_textarea textarea::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--main_color);
}

.part_container {
  padding: 0.5rem;
  background-color: white;
  border-radius: 0.5rem;
  outline: 1px solid lightgray;
  margin-bottom: 0.5rem;
}

/* note */
.note_container {
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  gap: 1rem;
  align-items: center;

  border-radius: 0.5rem;
  font-weight: 600;
  font-size: 0.9rem;
}

.note_container i {
  font-size: 2rem;
}

.warning {
  background-color: rgba(244, 244, 13, 0.7);
}

/*  */

.visible,
.hide {
  width: 100%;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  -webkit-transition: all 0.4s ease-in-out, opacity 0.2s linear;
  -moz-transition: all 0.4s ease-in-out, opacity 0.2s linear;
  -o-transition: all 0.4s ease-in-out, opacity 0.2s linear;
  transition: all 0.4s ease-in-out, opacity 0.2s linear;
}

.visible {
  max-height: 100%;
  opacity: 100;
  overflow: unset;
}

.error {
  color: red;
  font-weight: 600;
  font-size: 0.8rem;
}

.part_container .title {
  color: var(--main_color);
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.note {
  color: grey;
  font-size: 0.7rem;
  padding-right: 3%;
}

.photo_container {
  display: flex;
  gap: 0.5rem;
}

.photo_container>* {
  flex: 1 1 auto;
}

.input_container {
  display: flex;
  margin-bottom: 0.5rem;
  align-items: flex-start;
  justify-content: space-between;
}

.input_title {
  font-weight: 600;
}

.left {
  width: 40%;
  max-width: 40%;
}

.left p {
  margin: 0;
}

.right {
  width: 60%;
  max-width: 60%;
}

.input_textarea textarea {
  border-radius: 0.5rem;
  resize: none;
  width: 100%;
  min-height: 100px;
  padding: 0.5rem;
  border: 1px solid lightgray;

  outline: none;
}

.dropdown button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.3rem 0.5rem !important;
  border-radius: 0.5rem;
}

.selected_text {
  letter-spacing: 0;
  box-sizing: border-box;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  white-space: normal;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
}

.dropdown>div {
  width: 100%;
}

/* varian */
.varian_btn_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.varian_btn_container button {
  max-width: 210px;
}

/* input price container */
.price_container,
.discount {
  display: flex;
  align-items: center;
}

.price_container input {
  padding-left: 0.2rem;
  border-radius: 0 0.5rem 0.5rem 0;
}

.price_container span {
  font-weight: 600;
  background-color: lightgrey;
  padding: 0.26rem;
  border-radius: 0.5rem 0 0 0.5rem;
}

.discount input {
  border-radius: 0.5rem 0 0 0.5rem;
}

.discount span {
  font-weight: 600;
  background-color: lightgrey;
  padding: 0.28rem 0.5rem;
  border-radius: 0 0.5rem 0.5rem 0;
}

.volume_container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.volume_container>* {
  flex: 1 1 auto;
  min-width: 120px;
  max-width: 50%;
}

.radio_btn_container {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.radio_btn_container>* {
  flex: 1 1 auto;
  max-width: 50%;
}

.radio_input {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.radio_input>* {
  cursor: pointer;
}

.radio_input input {
  min-width: 1rem;
  box-shadow: none !important;
  border-color: var(--main_color) !important;
}

.radio_input input:checked {
  background-color: var(--main_color) !important;
}

.radius_container {
  display: flex;
  gap: 0.5rem;
  margin-top: 0.4rem;
}

.radius_container>* {
  flex: 1 1 auto;
}

.radius_container .dropdown button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.26rem 0.5rem !important;
  border-radius: 0.5rem;
}

.btn_container {
  display: flex;
  gap: 0.5rem;
}

.video_container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

@media screen and (max-width: 300px) {
  .radio_btn_container {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 0.5rem;
  }

  .radio_btn_container>* {
    max-width: none;
  }

  .radio_input {
    white-space: nowrap;
  }
}

@media screen and (max-width: 500px) {
  .photo_container {
    flex-wrap: wrap;
    justify-content: center;
  }

  .photo_container>* {
    max-width: 100px;
  }

  .radius_container {
    flex-direction: column;
  }
}

@media screen and (max-width: 850px) {
  .volume_container {
    flex-wrap: wrap;
    gap: 0.2rem;
  }
}