.container {
  height: 80vh;
  max-height: 80vh;
  padding: 0.5rem;
  display: flex;
  /*added*/
  flex-direction: column;
  /*added*/
  overflow-y: auto;
}

.status_btn {
  max-width: 100px;
}