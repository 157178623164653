.container {
	position: relative;
}

.list_container {
	list-style: none;
	position: absolute;
	z-index: 2;

	height: fit-content;
	margin-top: 0.3rem;
	padding: 0 0.5rem;

	font-weight: 600;
	border-radius: 0.5rem;
	box-shadow: 2px 2px 3px grey;
	background-color: white;

	display: flex;
	align-items: center;
}

.top {
	top: -250%;
	left: -50%;
}

.right {
	right: 0;
}

.left {
	left: 0;
}

.list_container li {
	padding: 0.2rem 0.1rem;
	margin: 0.2rem;
	cursor: pointer;
	border-radius: 0.5rem;
	white-space: nowrap;
}

.list_container i {
	font-size: 1.2rem;
	color: white;
	padding: 0.1rem 0.25rem;
	border-radius: 25%;
}

.down,
.up {
	visibility: hidden;
	overflow: hidden;
	-webkit-transition: visibility 0s linear;
	-moz-transition: visibility 0s linear;
	-o-transition: visibility 0s linear;
	transition: visibility 0s linear;
}

.down {
	visibility: visible;
	outline: 1px solid lightgrey;
}

.line {
	display: block;
	height: 23.8px;
	width: 23.8px;
	padding: 0;
	background-image: url("../assets/images/line-lg.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
}

@media screen and (max-width: 420px) {
	.top {
		left: -100%;
	}
}
