.container {
  position: relative;
  min-width: 150px;
}

.dropdown_btn {
  cursor: pointer;
  padding: 0.3rem 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid lightgrey;

  display: flex;
  justify-content: space-between;
}

.dropdown_list {
  position: absolute;
  list-style: none;
  padding: 0 0.5rem;
  margin-top: 0.2rem;
  width: 100%;
  z-index: 1;

  background-color: white;
  border-radius: 0.5rem;
}

.dropdown_list li {
  cursor: pointer;
  white-space: nowrap;
  padding: 0.3rem 0.5rem;
  margin-bottom: 0.2rem;
}

.dropdown_list li:first-child {
  margin-top: 0.5rem;
}

.dropdown_list li:last-child {
  cursor: initial;
  margin-bottom: 0.5rem;
}

/* .dropdown_list li:hover {
  color: white;
  background-color: var(--main_color);
  border-radius: 0.5rem;
}

.dropdown_list li:last-child:hover {
  color: black;
  background-color: white;
} */

.down,
.up {
  max-height: 0;
  overflow: hidden;
  border: 1px solid lightgray;
  box-shadow: 1px 1px 1px grey;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.down {
  max-height: 500px;
  visibility: visible;
}

.add_btn {
  color: var(--main_color);
  font-weight: 600;

  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

/* input */
.disable {
  color: gray !important;
  background-color: lightgrey !important;
  border-radius: 0.5rem;
}

.add_btn_container {
  width: 100%;
  border-top: 1px solid lightgrey;
}

.input_title {
  width: 100%;
  color: var(--main_color);
  font-size: 0.8rem;
  font-weight: 600;

  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  margin-top: 0.4rem;
}

.input_title span {
  max-width: fit-content;
}

.input_container {
  display: flex;
  align-items: center;
  margin-top: 0.2rem;
}

.input_container form {
  width: 100%;
}

.input_container input {
  border-radius: 0.5rem 0 0 0.5rem;
  border-right: none !important;
}

.input_container i {
  cursor: pointer;
  padding: 0.2rem 0.3rem;
  border: 1px solid lightgrey;
  border-left: none;
  border-radius: 0 0.5rem 0.5rem 0;
}

.new_type {
  display: flex;
  justify-content: space-between;
}

.new_type span {
  width: 100%;
}

.action_container {
  display: flex;
  gap: 0.1rem;
}

.action_container i {
  padding: 0.1rem 0.2rem;
  color: black;
}

.action_container i:hover {
  border-radius: 0.3rem;
  background-color: grey;
}

@media screen and (max-width: 500px) {
  .container {
    width: 100%;
  }
}
