.container {
  padding: 0.5rem;
  padding-top: 0;
}

.container_group {
  margin-bottom: 0.5rem;
}

.courier_container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, auto));
  place-content: center;
  gap: 0.5rem;
}

.card_container {
  padding: 0.5rem;
  max-width: 100px;
  border-radius: 0.5rem;
  border: 1px solid lightgray;

  cursor: pointer;
  text-align: center;
  box-shadow: 2px 2px 2px grey;
}

.key_title {
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 0.2rem;
}

.active {
  color: white;
  border: 1px solid var(--main_color);
  background-color: var(--main_color);
}

@media screen and (max-width: 380px) {
  .courier_container {
    grid-template-columns: repeat(auto-fill, minmax(120px, auto));
  }
}

