.line_container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.map_container {
  height: 100px;
  width: 100%;
  position: relative;
  margin-bottom: 0.5rem;
}

.map_cover {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;

  border: 1px solid grey;
  border-radius: 0.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  cursor: pointer;
}

.map_cover:hover #btn {
  visibility: visible;
  margin: 0.5rem;
  padding: 0.3rem;
  color: black !important;
  background-color: white;
  
  border-radius: 0.3rem;
  border: 1px solid lightslategrey;
}

.map_cover.disabled {
  color: lightgrey !important;
  background-color: transparent;
  cursor: not-allowed;
  border-color: lightgrey;

  justify-content: center;
  align-items: center;
}

.map_cover.disabled #btn {
  visibility: visible;
  border: none;
  color: lightgrey !important;

}

.map_cover #btn {
  visibility: hidden;
  color: grey;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 400px) {
  .line_container {
    flex-direction: column;
    gap: 0.2rem;
  }
}
