.container {
  height: 80vh;
  text-align: end;
}

.header {
  padding: 0.5rem 0;
}

.header button {
  padding: 0.3rem;
  margin-right: 0.5rem;
  max-width: fit-content;
}

.list_container {
  padding: 0 0.2rem;
  height: 70vh;
  overflow-y: scroll;
}

.list_inner_container {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.list_container::-webkit-scrollbar {
  width: 0.3rem;
}

.list_container::-webkit-scrollbar-track {
  border: 1px solid #ffffff;
  border-radius: 0 0.5rem 0.5rem 0;
  background-color: #ffffff;
}

.list_container::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--main_color);
}

.card, .sub_card {
  padding: 0.3rem 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-radius: 0.3rem;
  border: 1px solid var(--main_color);
  background-color: white;
  box-shadow: 1px 2px 2px gray;
}

.name {
  font-weight: 600;
}

.action_container {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.action_container button {
  border: none;
  font-weight: 600;
  padding: 0;

  min-width: 3em;
  height: 1.5rem;
}

.action_container button:hover {
  box-shadow: none !important
}

/* from */
.form_container button {
  margin-top: 0.5rem;
}

.form_container input {
  border-radius: 0.3rem;
}

/* sub etalase */
.sub_etalase_container {
  min-height: 0;
  padding-top: 0.3rem;
  margin-left: 5rem;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.warp_container {
  display: grid;
  /* overflow: hidden; */
  grid-template-rows: 0fr;
  transition: all 200ms;
}

.warp_container>div {
  margin-bottom: 0;
}

.warp_container.open>div {
  margin-bottom: 0.5rem;
}

.warp_container.open {
  grid-template-rows: 1fr;
}

.show_more:disabled {
  background-color: transparent !important;
  color: grey !important;
}

.seq_container {
  display: flex;
  color: var(--main_color);
  font-weight: 600;
  gap: 0.5rem;

  label {
    white-space: nowrap;
  }
}

.seq_drop_menu {
  min-width: unset;
  width: fit-content;
  border-radius: 0.3rem !important;
  max-height: 100px !important;
}

.seq_drop_item {
  padding: 0.2rem 0.5rem;
  text-align: center;
}