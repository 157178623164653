.container {
	border: 1px solid lightgray;
	border-radius: 0.5rem;
	position: relative;

	font-size: 0.7rem;
	font-weight: 600;
	color: var(--main_color) !important;
	display: flex;
	flex-direction: column;
	align-items: center;

	cursor: pointer;
	text-decoration: none;
	aspect-ratio: 1/1.1;
}

.container i {
	margin-right: 0.1rem;
}

.image_container {
	position: relative;
	max-height: 188px;
	height: 100%;
	border-radius: 0.5rem;
	margin: 1px;
}

.image_container img {
	border-radius: 0.5rem;
	width: 100%;
}

.detail_container {
	padding: 0.5rem;
	text-align: start;
	width: 100%;
}

.preorder_text,
.sold_out_text,
.closed_text {
	position: absolute;

	color: white;
	width: fit-content;
	background-color: grey;
	padding: 1px 5px;
	border-radius: 5px;
	font-size: 0.9rem;
	text-transform: none;
}

.preorder_text {
	top: 0.5rem;
	right: 0.5rem;
}

.sold_out_text {
	bottom: 0.5rem;
	right: 0.5rem;
}

.closed_text {
	left: 0;
	bottom: 0;
}

.title {
	/* text-align: center; */
	font-weight: 600;
	/* color: black; */
	letter-spacing: 0;
	box-sizing: border-box;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	line-clamp: 2;
	-webkit-line-clamp: 2;

	overflow: hidden;
	white-space: normal;
	overflow-wrap: break-word;
	font-size: 1rem;
	text-overflow: ellipsis;

	max-width: 188px;
	min-height: 2.4rem;
}

.price {
	font-size: 1rem;
}

.discount {
	color: red;
	background-color: rgb(255, 0, 0, 0.2);
}

.discount_container * {
	font-size: 0.6rem;
}

.discount_container {
	display: flex;
	gap: 0.5rem;
}

.discount_container .price {
	color: grey;
	text-decoration: line-through;
}
