.base_img {
  max-height: 400px !important;
  transform: scale(1) rotate(0);
}

.cropper_container {
  max-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;

  background-color: black;
}
