.container {
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.container, .btn_container{
  max-width: 800px;

}

.form_container {
  width: 100%;
  max-width: 600px;
  flex: 11;
}

.foto_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 1;
}

.foto_container .foto {
  border-radius: 50%;
  height: 150px;
  width: 150px;
  border: 1px solid black;
  display: inline-block;

  margin-bottom: 0.5rem;
}

.title {
  font-weight: 600;
  color: var(--main_color);
  margin: 0.5rem 0;
}

.field_container,
.field_container_contact {
  display: flex;
  gap: 0.5rem;
  padding-bottom: 0.5rem;
}

.inner_container {
  display: flex;
  gap: 0.5rem;
}

.input_label {
  min-width: 80px;
  white-space: nowrap;
}

.input_container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 0.2rem;
}

.text {
  min-width: 100px;
}

.text_verification {
  background-color: var(--main_color);
  padding: 0.1rem 0.4rem;
  color: white;
  border-radius: 0.2rem;
  font-size: 0.9rem;
  margin: 0 1rem;
}

.btn_text {
  color: var(--main_color);
  font-weight: 600;
  cursor: pointer;
  white-space: nowrap;
}

.btn_container {
  display: flex;
  justify-content: flex-end;
}

.btn_container button {
  margin: 0.5rem;
  max-width: 120px;
}

/* modal form */
.modal_container {
  text-align: center;
}

.modal_container button {
  margin-top: 1rem;
}

.dob_container {
  width: 100%;
  text-align: center;
}

.dob_container label {
  font-weight: 600;
  margin-bottom: 0.5rem;
  text-align: start;
}

.gender_container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.gender_input input {
  width: fit-content;
}

.gender_input input:checked {
  background-color: var(--main_color);
}

@media screen and (max-width: 600px) {
  .container {
    flex-direction: column;
  }
  .btn_container {
    margin: 0;
  }
}

@media screen and (max-width: 450px) {
  .field_container_contact {
    flex-direction: column;
    gap: 0.2rem;
  }

  .btn_container button {
    max-width: none;
    margin: 0.5rem 0;
  }
}

@media screen and (max-width: 280px) {
  .input_container {
    font-size: 0.85rem;
  }

  .text_verification {
    font-size: 0.8rem;
  }
}
