.container {
	min-height: 100vh;
}

.container > * {
	border-radius: 0.5rem;
}

.line_container {
	display: flex;
	overflow-x: scroll;
	gap: 0.5rem;
}

.line_container::-webkit-scrollbar {
	display: none;
}

.home_product_container {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(188px, 1fr));
	place-items: center;
	gap: 1rem;
}

.flex_container {
	display: flex;
	gap: 0.5rem;
}

.flex_container > * {
	flex: 1 1 auto;
	max-width: 50%;
}

.community_header {
	display: flex;
	align-items: center;
	gap: 0.5rem;
	margin: 0.5rem 0;
}

/* circle loading */
.container2 {
	width: 100%;
	text-align: center;
}
