.container {
  position: relative;
}

.container_menu {
  position: absolute;
  top: 3.5rem;
  width: 100%;
  background-color: white;
  border-radius: 0.4rem;
  box-shadow: 0px 0px 7px black;
  z-index: 30;
}

.hide,
.show {
  max-height: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: max-height 0.5s, visibility 0s, opacity 0.5s ease-in-out;
  -moz-transition: max-height 0.5s, visibility 0s, opacity 0.5s ease-in-out;
  -o-transition: max-height 0.5s, visibility 0s, opacity 0.5s ease-in-out;
  transition: max-height 0.5s, visibility 0s, opacity 0.5s ease-in-out;
}

.show {
  display: block;
  visibility: visible;
  opacity: 1;
  max-height: 100vh;
}

.tab_menu {
  list-style: none;
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
}

.menu_item {
  flex: 1 1 auto;
  width: 0.9rem;
  text-align: center;
  font-weight: 700;
  color: var(--main_color);
}

.not_able {
  cursor: not-allowed !important;
}

.menu_item_container {
  max-height: 10rem;
  overflow-y: scroll !important;
  padding: 0 0.5rem;
  margin-bottom: 0.5rem;
}

.menu_item_container::-webkit-scrollbar {
  width: 0.3rem;
}

.menu_item_container::-webkit-scrollbar-track {
  border: 1px solid #ffffff;
  border-radius: 0 0.5rem 0.5rem 0;
  background-color: #ffffff;
}

.menu_item_container::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--main_color);
}

.item {
  padding: 0.2rem 0.5rem;
  border-radius: 0.3rem;
  cursor: pointer;
  font-weight: 600;
}

.item:hover {
  color: #ffffff;
  background-color: var(--main_color);
}

.item.selected {
  color: #ffffff;
  background-color: var(--main_color);
}

/* other */
@media screen and (max-width: 400px) {
  .tab_menu {
    flex-direction: column;
  }
}
