.warning_container {
    display: flex;
    gap: 0.5rem;
    padding-block: 0.5rem;
    border-radius: 0.5rem;
    margin-bottom: 0.5rem;

    font-weight: 600;
}

.donwload_container {
    display: flex;
    gap: 0.5rem;
    font-weight: 600;
}

.input_file {
    width: 100%;
    margin-top: 0.5rem;
    display: flex;
    gap: 0.5rem;

    button{
        width: 100px;
    }

    input{
        border-radius: 0.5rem;
    }

    & :focus{
        border-color: transparent;
        box-shadow: 1px 1px 1px hsla(0, 0%, 50%, 0.5);
    }
}