.list_container {
    height: 100%;
    overflow-y: scroll;
    padding: 0 0.5rem;
}

.list_container::-webkit-scrollbar {
    width: 0.3rem;
}

.list_container::-webkit-scrollbar-track {
    border: 1px solid #ffffff;
    border-radius: 0 0.5rem 0.5rem 0;
    background-color: #ffffff;
}

.list_container::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: var(--main_color);
}