.container {
  --size: 50px;
  display: flex;
  justify-content: center;
  max-width: var(--size);
  padding: 0.3rem;
}

.container input {
  display: none;
}

.img_btn,
.btn_input {
  border-radius: 0.5rem;
}

.img_btn img {
  max-height: var(--size);
  max-width: var(--size);
  border-radius: 0.5rem;
}

.img_btn_container {
  position: relative;
}

.img_btn_container i {
  font-size: 1.2rem;
  position: absolute;
  cursor: pointer;
  right: 2px;
  bottom: 2px;

  background-color: white;
  border-radius: 0.5rem;
  /* opacity: 0; */
}

/* .img_btn_container i:hover {
  opacity: 100;
} */

.btn_input {
  font-size: 2rem;
  border: 1px dashed black;
  padding: 0 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  max-height: 100px;
  text-align: center;
}

.round {
  font-size: 3rem;
  border-radius: 50%;
  width: 100px;
}
