.page_container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	height: 100%;
	background-color: #583985;
	padding-bottom: var(--def_padding_top);
}

/* term and policy */
.term_container {
	display: flex;
	gap: 0.5rem;

	:is(a) {
		font-weight: 600;
		cursor: pointer;
	}
}

.checked_container {
	align-self: center;
	font-size: 1.2rem;
	border: none !important;
	background: none !important;

	outline: none !important;
}

.checked_container:disabled > * {
	color: grey;
	cursor: not-allowed;
}

/* register user form */
.page_form_container {
	width: 100%;
	padding: 1rem;
	border: 1px solid white;
	border-radius: 0.5rem;
	background-color: rgb(255, 255, 255);
}

.register_sub {
	display: flex;
	justify-content: center;
}

.register_form {
	width: 100%;
	min-height: 100px;
}

.otp_message {
	width: 100%;
	text-align: center;
	margin: 0.5rem 0;
	font-size: 0.8rem;
}

.btn_login {
	/* color: #583985 !important; */
	cursor: pointer;
	font-weight: 600;
	margin-left: 0.5rem;
	text-decoration: none;
}

.timer_container {
	width: 100%;
	display: flex;
	justify-content: center;
	font-size: 0.7rem;
}
