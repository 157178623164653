.container * {
	font-family: "Lato";
	color: var(--sec_color);

	--height: 4.8rem;
}

.container {
	position: fixed;
	width: 100vw;
	background-color: var(--main_color);
	z-index: 30;

	/* position: sticky;
	top: 0; */
}

.inner_container {
	padding: 0.5rem 5vw;
	display: flex;
	gap: 0.5rem;
	align-items: center;
	justify-content: space-between;
	position: relative;
}

.header_prt1 {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: var(--height);
}

.header_prt2 {
	flex: 8;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-left: 2rem;
}

.header_prt2 .show {
	flex: 8;
	flex-direction: row;
}

.before_login_container {
	display: flex;
	padding: 0.5rem 0;
}

.icon_menu_container {
	display: flex;
	padding: 0.5rem;
	gap: 0.5rem;
}

.icon_menu {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.2rem 0.5rem;
	cursor: pointer;
}

.icon_menu:hover {
	background-color: rgb(255, 255, 255, 0.1);
	border-radius: 0.5rem;
}

.icon_menu i {
	font-size: 1.2rem;
}

/* store */
.store_container {
	display: flex;
	align-items: center;
	cursor: pointer;
	text-decoration: none;
}

.store_logo {
	max-height: var(--height);
	margin-right: 1rem;
	border-radius: 50%;
	background-color: white;
	min-height: 50px;
	min-width: 50px;
}

.store_logo.not {
	border-radius: none;
	background-color: transparent;
}

.store_name {
	font-size: 1.2rem;
	font-weight: 600;
	max-width: 210px;
	overflow-wrap: anywhere;
}

.icon_container {
	display: none;
}

.icon_container i::before {
	border: 2px solid var(--main_color);
	border-radius: var(--def_bor_rad);
	cursor: pointer;
}

.icon_container i:hover::before {
	border: 2px solid rgb(255, 255, 255);
	border-radius: var(--def_bor_rad);
}

/* search */
.search_container {
	flex: 5 5 auto;
}

/* nav menu */
.nav_menu {
	list-style-type: none;
	padding: 0;
	margin: 0.3rem 0;
	display: flex;
	flex: 1 1 auto;
	justify-content: flex-end;
	align-items: center;
}

.nav_menu li {
	font-size: 1.1rem;
	font-weight: 600;
	cursor: pointer;

	display: flex;
	align-items: center;
}

.nav_menu li > a {
	color: white !important;
	text-decoration: none;
	white-space: nowrap;
	padding: 0.2rem 0.5rem;
}

.nav_menu li > div > a {
	color: white !important;
	text-decoration: none;
	white-space: nowrap;
	padding: 0.5rem;
}

.nav_menu li > div > a:focus-visible {
	outline: none;
}

/* komunitas */
.community_title {
	color: white;
	font-weight: 900;
	font-size: 2rem;
}

@media screen and (max-width: 850px) {
	.inner_container {
		gap: 0;
		flex-direction: column;
		align-items: stretch;
		justify-content: center;
	}

	.header_prt2 {
		flex-direction: column;
		margin: 0;
	}

	.slideup,
	.slidedown {
		max-height: 0;
		opacity: 0;
		overflow: hidden;
		-webkit-transition: all 0.5s ease-in-out;
		-moz-transition: all 0.5s ease-in-out;
		-o-transition: all 0.5s ease-in-out;
		transition: all 0.5s ease-in-out;
	}

	.slidedown {
		max-height: 100vh;
		opacity: 1;
		overflow: unset;
	}

	/* store */
	.store_name {
		font-size: 1.2rem;
	}

	.icon_container {
		display: flex;
		font-size: 2rem;
	}

	/* search */
	.search_container {
		flex: 1 1 auto;
		width: 100%;
		padding-top: 0.5rem;
	}

	/* nav menu */
	.nav_menu {
		flex: 1 1 auto;
		flex-direction: column;
		width: 100%;
		background-color: var(--main_color);
	}

	.nav_menu li > div > a {
		border-radius: var(--def_bor_rad);
	}

	.nav_menu li > div > a:hover {
		color: var(--main_color) !important;
		background-color: rgb(255, 255, 255, 0.9);
	}
}

@media screen and (max-width: 400px) {
	/* store */
	.store_name {
		font-size: 1.2rem;
	}

	.exception .header_prt1 {
		justify-content: center;
	}

	.exception .store_logo {
		margin: 0;
	}
}

/* exption */
.exception {
	color: #583985 !important;
	/* background-color: #583985 !important; */
}

.exception .nav_menu {
	display: none;
	color: #583985 !important;
	background-color: #583985 !important;
}

.exception .search_container {
	display: none !important;
}

/* .exception .store_name {
	display: none !important;
} */

.exception .icon_container {
	display: none !important;
}
