.container * {
  line-height: normal;
}

.container {
  display: flex;
  flex-direction: column;

  max-height: 80vh;
  overflow-y: hidden;
  padding: 0.5rem;
}

.sub_header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  gap: 0.5rem;
}

.search {
  flex: 1 1 auto;
}

.list_outer_container {
  height: 100%;
  overflow-y: scroll;
}

.list_outer_container::-webkit-scrollbar {
  width: 0.3rem;
}

.list_outer_container::-webkit-scrollbar-track {
  border: 1px solid #ffffff;
  border-radius: 0 0.5rem 0.5rem 0;
  background-color: #ffffff;
}

.list_outer_container::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--main_color);
}

.list_container {
  width: 100%;
  padding: 0 0.2rem;
  display: flex;
  flex-direction: column;
}

/* card */
.card_container {
  padding: 0.5rem;
  border: 2px solid var(--main_color);
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
}

.dtl_container #name {
  font-size: 1.2rem;
}

.dtl_container #address {
  text-transform: capitalize;
}

.dtl_container #pin_point * {
  color: var(--main_color);
  padding-right: 0.5rem;
}

.dtl_container_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 900;
}

.dtl_container_header #badge {
  padding: 0.2rem 0.5rem;
  border-radius: 0.2rem;
  color: #ffffff;
  background-color: var(--main_color);
}

.act_container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 0.5rem;
}

.action_i {
  cursor: pointer;
  color: var(--main_color);
  font-weight: 600;
}

@media screen and (max-width: 400px) {
  .sub_header {
    flex-direction: column;
  }

  .sub_header button {
    padding: 0.3rem;
  }

  .dtl_container {
    font-size: 0.9rem;
  }

  .act_container {
    flex-direction: column;
    gap: 0.2rem;
  }

  .action_i {
    color: #ffffff;
    padding: 0.3rem;
    background-color: var(--main_color);

    border-radius: 0.3rem;
    text-align: center;
  }
}
