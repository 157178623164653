.container {
    height: 80vh;
    text-align: end;
}

.header {
    padding: 0.5rem 0;
}

.header button {
    padding: 0.3rem;
    margin-right: 0.5rem;
    max-width: fit-content;
}

.list_container {
    padding: 0 0.2rem;
    height: 70vh;
    overflow-y: scroll;
}

.list_inner_container {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.list_container::-webkit-scrollbar {
    width: 0.3rem;
}

.list_container::-webkit-scrollbar-track {
    border: 1px solid #ffffff;
    border-radius: 0 0.5rem 0.5rem 0;
    background-color: #ffffff;
}

.list_container::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: var(--main_color);
}

/* card */
.card {
    padding: 0.3rem 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-radius: 0.3rem;
    border: 1px solid var(--main_color);
    background-color: white;
    box-shadow: 1px 2px 2px gray;
}

.fieldGroup {
    display: flex;
    gap: 0.5rem;
}

.fieldName {
    min-width: 100px;
    text-align: left;
    display: flex;
    justify-content: space-between;
}

.fieldName::after {
    content: ':';
}

.action_container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.action_container button {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
}