.sub_header {
  display: flex;
  flex-direction: column;
  border: 1px solid lightgrey;
  border-radius: 0.5rem;
  padding: 0.3rem 0.5rem;
  box-shadow: 2px 2px 2px;
  margin-bottom: 0.5rem;
}

.text_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 30px;
}

.text_container #name {
  font-weight: 600;
}

.text_container #discount {
  font-weight: 600;
  color: red;
}

.item_container {
  padding: 0 0.5rem;
  margin: 0.5rem 0;
  min-height: 90px;
}

.btn_container {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}

/* after */
.bank_container {
  margin-bottom: 1rem;
}

.bank_container .text_container {
  border-bottom: 1px solid lightgrey;
}

.via_container {
  display: flex;
  flex-direction: column;
}

.via_container #label {
  font-weight: 600;
}

.via_container img {
  align-self: center;
  max-height: 8rem;
  width: fit-content;
}

.va_number_container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.va_number_container button {
  border: none;
  border-radius: 0.2rem;
  background-color: white;
  margin: 5px 0;
}

.va_number_container button:hover {
  background-color: lightgrey;
}