/* ALEGREYA */
@import url("https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400;0,500;0,700;0,800;0,900;1,400;1,500;1,700;1,800;1,900&display=swap");

/* LATO & OPEN SANS */
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&family=Open+Sans:wght@300;400;600;700;800&display=swap");

@font-face {
  font-family: "Lato";
  src: local("Lato"),
    url("../../assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "LatoBlack";
  src: local("LatoBlack"),
    url("../../assets/fonts/Lato/Lato-Black.ttf") format("truetype");
}
