.container {
  padding: 0.5rem;
}

.header {
  display: flex;
  justify-content: space-between;
}

.header button {
  max-width: 120px;
  justify-self: flex-end;
}

/* varian */
.type_input_container {
  width: 100%;
  margin: 0.5rem 0;
}

.type_input_container button {
  max-width: 120px;
}

.title {
  color: var(--main_color);
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 0.3rem;
}

.input_container,
.input_container1 {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.input_container .trash {
  font-size: 1.4rem;
  padding: 0.15rem;
  border-radius: 0.5rem;
  cursor: pointer;
}

.input_part_container {
  display: flex;
  width: 100%;
  gap: 0.5rem;
}

.input_part_container > * {
  flex: 1 1 auto;
}

/* .trash:hover {
  color: white;
  background-color: rgb(255, 0, 0);
} */

/* varian list */
.varian_list_container {
  width: 100%;
}

/* input all */
.input_all_container {
  width: 100%;
}

.input_all_container .title {
  font-size: 1rem;
  width: fit-content;
}

.input_all_container > div {
  margin-top: 0.5rem;
  padding: 0.5rem;
  border: 1px solid lightgray;
  border-radius: 0.5rem;
}

.input_container1 {
  align-items: flex-end;
  width: 100%;
}

.input_container1 button {
  max-width: 120px;
}

@media screen and (max-width: 500px) {
  .input_container {
    flex-direction: column;
    gap: 0.2rem;
  }
}

@media screen and (max-width: 800px) {
  .input_container1 {
    flex-direction: column;
    align-items: flex-start;
  }

  .input_container1 button {
    max-width: none;
  }
}
