.container {
  position: relative;
  font-size: 1.3rem;
}

.container input {
  /* opacity: 0; */
  display: none;
  position: absolute;
  /* left: -9000px;
  top: -9000px; */
}

.container input+label {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.container input+label::before {
  content: "";
  width: 100%;
  min-width: 2.2em;
  height: 1em;
  background-color: rgb(128, 128, 128, 0.25);
  border-radius: 1em;
  margin-inline: 0.2em;
  transition: background-color 200ms ease-in-out;
}

.container input+label::after {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  content: "";
  font-size: 0.5em;
  height: 0.9em;
  left: calc(100% /10);
  width: 1.8em;
  height: 1.8em;
  background-color: grey;
  color: white;
  border-radius: 1em;
  transition: all 200ms ease-in-out;
}

.container input+label::before {
  outline: 1px solid lightgrey;
}

.container input:checked+label::before {
  background-color: rgb(128, 128, 128, 0.25);
}

.container input:checked+label::after {
  left: calc(90%);
  transform: translateX(-100%);
  background-color: var(--main_color);
}

.container input:disabled+label {
  color: #777;
}

.container input:disabled+label::before {
  background-color: #ccc;
}

.container input:disabled+label::after {
  background-color: #777;
}