.container {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

._main {
  border-bottom: 1px solid lightgray;
  margin-top: 0.5rem;
  padding-bottom: 0.2rem;
}

.container > * {
  flex: 1 1 auto;
}

.input_container {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.input_title {
  align-self: flex-start;
  font-weight: 600;
  min-width: 50px;
}

.price_container {
  display: flex;
  width: 100%;
}
.price_container input {
  padding-left: 0.2rem;
  border-radius: 0 0.5rem 0.5rem 0;
}

.price_container span {
  font-weight: 600;
  background-color: lightgrey;
  padding: 0.3rem;
  border-radius: 0.5rem 0 0 0.5rem;
}

.action_container,
.action_part {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

._primary {
  text-align: center;
  min-width: 65px;
  font-weight: 600;
}

._primary span {
  color: white;
  padding: 0.3rem 0.5rem;
  border-radius: 0.5rem;
  background-color: var(--main_color);
}

._primary i {
  font-size: 1.5rem;
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  .container {
    flex-wrap: wrap;
  }

  .input_container {
    flex-direction: row;
  }

  .input_title {
    align-self: center;
  }
}

@media screen and (max-width: 500px) {
  .action_container {
    justify-content: space-between;
  }
}
