.container * {
  line-height: normal;
}

.container {
  display: flex;
  flex-direction: column;

  max-height: 80vh;
  overflow-y: hidden;
  padding: 0.5rem;
}

.sub_header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  gap: 0.5rem;
}

.sub_header input {
  width: 60%;
}

.sub_header button {
  width: fit-content;
  white-space: nowrap;
}

.list_outer_container {
  height: 100%;
  overflow-y: scroll;
}

.list_container {
  width: 100%;
  padding: 0 0.2rem;
}

.list_outer_container::-webkit-scrollbar {
  width: 0.3rem;
}

.list_outer_container::-webkit-scrollbar-track {
  border: 1px solid #ffffff;
  border-radius: 0 0.5rem 0.5rem 0;
  background-color: #ffffff;
}

.list_outer_container::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--main_color);
}

/* card */
.card {
  padding: 0.5rem;
  border: 2px solid var(--main_color);
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
}

.bank_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bank_container #name {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--main_color);
}

.bank_container #primary {
  color: white;
  padding: 0.3rem;
  background-color: var(--main_color);
  border-radius: 0.3rem;
}

.text_container {
  display: flex;
  gap: 0.5rem;
}

.text_container #left {
  width: 85px;
}

.text_container #right::before {
  content: ":";
  padding-right: 0.5rem;
}

.action_container {
  display: flex;
  justify-content: flex-end;
  color: var(--main_color);
  margin-top: 0.5rem;
}

.action_container .action_i:last-child {
  border: none;
}

.action_i {
  font-weight: 600;
  font-size: 0.8rem;
  cursor: pointer;
  padding: 0 0.5rem;
  border-right: 1px solid var(--main_color);
}

/* form */
.input_container {
  margin-top: 0.2rem;
}

.input_container label {
  padding: 0 0.2rem;
  color: var(--main_color);
  font-weight: 600;
  font-size: 0.9rem;
}

.btn_container {
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.bank_dropdown button {
  width: 100%;
  height: 2rem;
  text-align: start;
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-radius: 0.5rem;
}

@media screen and (max-width: 400px) {
  .sub_header {
    flex-direction: column;
  }

  .sub_header input {
    width: 100%;
  }

  .sub_header button {
    width: 100%;
    padding: 0.3rem;
  }

  .action_container {
    flex-wrap: wrap;
    gap: 0.2rem;
  }

  .action_i {
    flex: 1 1 auto;
    color: #ffffff;
    padding: 0.3rem;
    background-color: var(--main_color);

    border-radius: 0.3rem;
    text-align: center;
    width: fit-content;
  }
}
