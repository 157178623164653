.container {
    padding: 0.5rem;
    max-height: 80vh;
    height: 80vh;

    overflow: auto;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header span {
    font-weight: 600;
    white-space: nowrap;
}

.date_container input {
    border: none;
    border-bottom: 1px solid #d3d3d3;
    padding: 0.2rem 0.4rem;
    outline: none;
    text-align: center;
    width: 100px;
    background: transparent;
}

.body {
    display: flex;
    justify-content: space-between;
}

.dtl_container {
    flex: 7;
    padding: 0.5rem;
}

.dtl_part {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
}

.dtl_part>* {
    flex: 1 1 auto;
    max-width: 50%;
}

.rank_part {
    padding: 0.5rem;
    margin-top: 0.5rem;
    border: 1px solid lightgrey;
    border-radius: 0.5rem;
    min-width: 20vw;
}

.rank_list_container {
    max-height: 65vh;
    overflow: auto;
}

.rank_list_container::-webkit-scrollbar {
    display: none;
}

.rank_part .title {
    font-weight: 600;
    padding: 0.5rem 0;
}

.chart_container {
    max-height: 200px;
}

/* dtl card */
.dtl_card_container {
    border: 1px solid lightgrey;
    border-radius: 0.5rem;
    padding: 0.5rem;

    font-weight: 600;
    text-align: center;
}

/* rank card */
.rank_card_container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 0.5rem;
}

.product_name {
    max-width: 100%;
    letter-spacing: 0;
    box-sizing: border-box;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    white-space: normal;
    overflow-wrap: break-word;
    font-size: 0.9rem;
    text-overflow: ellipsis;
}

.rank_card_container img {
    max-width: 50px;
    padding: 5px;
    border: 1px solid lightgrey;
    border-radius: 0.5rem;
}

@media screen and (max-width:400px) {
    .header {
        flex-direction: column;
        align-items: flex-end;
    }

    .dtl_part {
        flex-direction: column;
    }

    .dtl_part>* {
        max-width: none;
    }

    .dtl_card_container {
        text-align: start;
    }
}

@media screen and (max-width:1000px) {
    .body {
        flex-direction: column;
    }
}