.outer_navbar {
  width: 100%;
  padding: 0.5rem 0.5rem;
  cursor: pointer;
  border-radius: 0.5rem;
}

.navdrop>a::after{
  content: none;
}

.navdrop>div>a {
  color: var(--main_color) !important;
  font-weight: 600;
  font-size: small;

  padding: 0.3rem 0.5rem;
  cursor: pointer;
  border-radius: 0.5rem;

  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.navdrop>div>a:hover {
  color: white !important;
  background-color: var(--main_color);
}

.seller_container {
  display: flex;
  gap: 0.5rem;
  width: 100%;
}

.seller_container img {
  min-width: 50px;
}

.store_name {
  color: var(--main_color) !important;
  white-space: nowrap;
  margin-bottom: 0.5rem;
}