/* form */
.input_container label {
	font-weight: 600;
}

.input_hp_container {
	display: flex;
	align-items: center;
}

.input_hp_container input {
	border-radius: 0 0.5rem 0.5rem 0;
	border-left: none !important;
}

.input_hp_container input:disabled {
	background-color: #e9ecef;
}

.input_hp_container span {
	border: 1px solid lightgray;
	border-radius: 0.5rem 0 0 0.5rem;
	padding: 0.2rem 0.5rem;
	background-color: #e9ecef;
	font-weight: 600;
}

.group_container {
	display: flex;
	gap: 0.5rem;
}

.group_container > * {
	flex: 1 1 50%;
}

.list_container {
	position: relative;
	padding: 0.5rem;
	padding-top: 1rem;
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	border: 1px solid lightgray;
	border-radius: 0.5rem;
}

.list_container > label {
	position: absolute;
	top: -0.75em;
	background-color: white;
	padding-inline: 0.5rem;
}

.check_input {
	display: flex;
	gap: 0.5rem;
	align-items: center;
}

.check_input > * {
	cursor: pointer;
}

.check_input input {
	cursor: pointer;
	accent-color: var(--main_color);
}

.etalase_container {
	margin-block: 0.25rem;
}

.subetalase_container {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(25%, auto));

	margin-left: 2em;
}

.button_container {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 0.5rem;
}

.button_container button {
	max-width: 120px;
}
