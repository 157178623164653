.container {
  height: 80vh;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
}

.sub_title {
  display: flex;
  gap: 0.5rem;
  font-weight: 600;
}

.sub_title span:first-child {
  color: var(--main_color);
}

.list_container {
  padding: 0 0.2rem;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.list_inner_container {
  width: 100%;
  padding: 0 0.2rem;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, auto));
  place-content: center;
  gap: 0.5rem 0;
}

.list_container::-webkit-scrollbar {
  width: 0.3rem;
}

.list_container::-webkit-scrollbar-track {
  border: 1px solid #ffffff;
  border-radius: 0 0.5rem 0.5rem 0;
  background-color: #ffffff;
}

.list_container::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--main_color);
}

/* product card */
.card_container {
  margin: 0.5rem;
  padding: 0.5rem;
  border: 1px solid lightgray;
  border-radius: 0.5rem;
  position: relative;

  font-size: 0.7rem;
  font-weight: 600;
  color: var(--main_color);
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 150px;

  cursor: pointer;
}

.card_container i {
  margin-right: 0.1rem;
}

.image_container {
  position: relative;
  text-align: center;
  width: 100%;
}

.btn_container {
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.btn_container button {
  font-size: 1rem;
}

/* action */
.action_btn {
  width: fit-content;
  cursor: pointer;
  padding: 0.2rem 0.5rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.5rem;
  outline: 1px solid lightgray;
  font-weight: 600;
  font-size: 1.2rem;
}