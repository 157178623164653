html,
body {
  height: 100%;
  margin: 0;
  font-size: 14px;
}

#root {
  /* height: 100vh; */
  font-family: "Lato", "Open Sans", sans-serif;
}

:root {
  --main_color: rgb(88, 57, 133);
  --sec_color: rgb(255, 255, 255);
  --def_bor_rad: 0.5rem;
  --def_padding_top:5.8rem;
}

/* .App {
  height: 100%;
  width: 100%;
} */

/* custom */
.custom-modal-content {
  border-radius: 1rem !important;
}

.input-group-text {
  cursor: pointer;
  border-radius: 0 0.5rem 0.5rem 0;
  padding: 0.2rem 0.75rem;
  border-color: lightgrey;
}
