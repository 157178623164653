.color_input_container {
  --block_size: 2rem;

  display: flex;
  align-items: center;
  gap: 0.2rem;

  height: 100%;
  min-width: 250px;
  padding: 0.1rem;
}

.color_block_container {
  display: grid;
  grid-template-columns: repeat(5, 28px);
  width: 150px;
  max-height: 100px;
  overflow-y: scroll;
}

.color_block_container::-webkit-scrollbar {
  width: 0.3rem;
}

.color_block_container::-webkit-scrollbar-track {
  border: 1px solid #ffffff;
  border-radius: 0 0.5rem 0.5rem 0;
  background-color: #ffffff;
}

.color_block_container::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--main_color);
}

.color_block {
  width: var(--block_size);
  height: var(--block_size);
}

.color_block.selected {
  border: 2px solid black;
}

.color_block_selected {
  width: 100%;
  flex: 1 1 40%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.color_block_selected label {
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
}

.color_block_selected > .color_block {
  width: 100%;
  height: 60px;
}

.title {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.2rem;
}
