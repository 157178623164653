.map_container {
  height: 400px;
}

.btn_container {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  margin-top: 1rem;
}

@media screen and (max-width: 400px) {
  .map_container {
    height: 450px;
  }
}
