* {
  line-height: normal !important;
}

.container {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  padding-top: 0.5rem 0;
  position: relative;
}

.nav_menu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0.2rem;
  padding-top: 0.5rem;
  justify-content: center;
  gap: 0.3rem;

  background-color: white;
}

.nav_menu button {
  flex: 1 1 10rem;
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.9rem;
  padding: 0.5rem;
  line-height: normal;
  border-radius: 0.3rem !important;
}

.sub_header {
  display: flex;
  flex-direction: row;
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  padding: 0 0.5rem;
  margin: 0.5rem 0;
  gap: 0.5rem;

  background-color: white;
}

.sub_header #search,
#date {
  flex: 1 1 auto;
  padding: 0.5rem;
  line-height: normal;
}

#date {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  text-align: center;
}

#date span {
  white-space: nowrap;
}

#date input {
  border: none;
  border-bottom: 1px solid lightgrey;
  outline: none !important;
  text-align: center;
  max-width: 110px;
}

.trans_container_outer {
  height: 100%;
  overflow-y: scroll;
}

.trans_container {
  width: 100%;
  padding: 0 0.3rem;
}

.trans_container_outer::-webkit-scrollbar {
  width: 0.3rem;
}

.trans_container_outer::-webkit-scrollbar-track {
  border: 1px solid #ffffff;
  border-radius: 0 0.5rem 0.5rem 0;
  background-color: #ffffff;
}

.trans_container_outer::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--main_color);
}

/* transaction detail view */
.trans_container_item {
  padding: 0.5rem;
  border: 1px solid var(--main_color);
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
}

.inner_trans_container1 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 0 0.5rem;
  padding-bottom: 0.5rem;

  font-weight: 600;
  border-bottom: 1px solid var(--main_color);
}

.inner_trans_container1 #stat {
  text-transform: capitalize;
}

/* trans product detail */
.inner_trans_container2 {
  display: flex;
  flex-direction: row;
  margin-top: 0.5rem;
  text-transform: capitalize;
  gap: 0 0.5rem;
}

/* item dtl */
.trans_item_container {
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
}

.trans_item {
  font-weight: 600;
  max-height: 120px;
  overflow-y: hidden;
}

.trans_item,
.show {
  -webkit-transition: max-height 0.5s ease-in-out;
  -moz-transition: max-height 0.5s ease-in-out;
  -o-transition: max-height 0.5s ease-in-out;
  transition: max-height 0.5s ease-in-out;
}

.show {
  overflow-y: scroll;
  max-height: 220px;
}

.show::-webkit-scrollbar {
  width: 0.3rem;
}

.show::-webkit-scrollbar-track {
  border: 1px solid #ffffff;
  border-radius: 0 0.5rem 0.5rem 0;
  background-color: #ffffff;
}

.show::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--main_color);
}

.show_more {
  margin-left: 100px;
  margin-top: 0.5rem;
  cursor: pointer;

  color: var(--main_color);
  font-weight: 600;
  font-size: 0.9rem;
  text-transform: none;
}

/* data transaksi dan user */
.trans_detail {
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.trans_detail .detail td {
  padding-right: 0.5rem;
  vertical-align: top;
}

.detail td:first-child {
  white-space: nowrap;
}

.btn_container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.2rem 0.5rem;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
}

.btn_container button {
  width: fit-content;
  width: 10rem;
  white-space: nowrap;
}

.exp_date {
  color: var(--main_color);
  font-weight: 600;
  font-size: 0.8rem;
  text-align: end;
  padding: 0 0.5rem;
  margin-top: 0.5rem;
}

/* pending */
.exp_time,
.exp_text {
  font-weight: 600;
  text-align: center;
  border-radius: 0.5rem;
}

.exp_time {
  padding-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
}

.exp_text {
  padding: 0.5rem;
  color: white;
  background-color: rgb(255, 0, 0);
}

@media screen and (max-width: 400px) {
  .sub_header {
    flex-direction: column;
  }

  .btn_container button {
    flex: 1 1 auto;
  }
}

@media screen and (max-width: 850px) {
  .inner_trans_container2 {
    flex-direction: column;
    gap: 0.5rem;
  }

  .detail {
    width: fit-content;
  }
}