.container {
  width: 100%;
  min-height: 1.85rem;
  border-radius: 0.5rem;
  outline: 1px solid lightgrey;
  padding: 0.15rem 0.5rem;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.2rem;
}

.input_container {
  width: 20px;
  position: relative;
}

.input_container input {
  outline: none !important;
  border: none;
  opacity: 1;
  background: none;
  max-width: fit-content;
}

.input_container label {
  position: absolute;
  top: 0;
}

.disabled {
  background-color: rgb(128, 128, 128, 0.5);
}

/* varian_card */
.varian_card {
  padding: 0.2rem;
  background-color: lightgrey;
  border-radius: 0.4rem;
}

.varian_card i {
  cursor: pointer;
  margin-left: 0.2rem;
}

@media screen and (max-width: 500px) {
}
