.container {
  position: relative;
}

.list_container {
  list-style: none;
  padding: 0 0.5rem;
  position: absolute;
  margin-top: 0.2rem;

  width: 100%;
  border-radius: 0.5rem;
  background-color: white;
  box-shadow: 2px 2px 2px rgb(128, 128, 128, 0.75);
  z-index: 30;
}

.list_container li {
  padding: 0.3rem 0.5rem;
  border-radius: 0.5rem;
}

.list_container li:first-child {
  margin-top: 0.4rem;
}

.list_container li:last-child {
  margin-bottom: 0.4rem;
}

.list_container li:hover {
  color: white;
  background-color: var(--main_color);
  cursor: pointer;
}

.list_container::-webkit-scrollbar {
  width: 0.3rem;
}

.list_container::-webkit-scrollbar-track {
  border: 1px solid #ffffff;
  border-radius: 0 0.5rem 0.5rem 0;
  background-color: #ffffff;
}

.list_container::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--main_color);
}

.down,
.up {
  max-height: 0;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-transition: max-height 0.2s ease-in-out;
  -moz-transition: max-height 0.2s ease-in-out;
  -o-transition: max-height 0.2s ease-in-out;
  transition: max-height 0.2s ease-in-out;
}

.down {
  max-height: 400px;
  outline: 1px solid lightgrey;
}

.empty {
  color: grey !important;
  background-color: white !important;
  cursor: default !important;
  text-align: center;
}
