.container {
  background-color: lightgray;
  border-radius: 0.5rem;
  padding: 0.2rem 0;
  margin-top: 0.5rem;
}

.btn_container {
  display: flex;
  justify-content: space-between;

  font-weight: 600;
  padding: 0 0.5rem;
  margin: 0.2rem 0;

  cursor: pointer;
}

/* dtl varian */
.dtl_container {
  display: flex;
  align-items: flex-end;
  gap: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid rgb(128, 128, 128, 0.5);
}

.dtl_container:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.dtl_container_part1 {
  flex: 4;
  display: flex;
  align-items: flex-end;
  width: 100%;
  justify-content: space-between;
}

.dtl_container_part2 {
  flex: 8;
  width: 100%;
  gap: 0.5rem;
  display: flex;
}

/* list_container */
.down,
.up {
  max-height: 0;
  overflow-y: auto;
  overflow-x: hidden;

  padding: 0 0.5rem;
  padding-bottom: 0;
  -webkit-transition: max-height 0.5s ease-in-out;
  -moz-transition: max-height 0.5s ease-in-out;
  -o-transition: max-height 0.5s ease-in-out;
  transition: max-height 0.5s ease-in-out;
}

.down {
  max-height: 400px;
  padding-bottom: 0.5rem;
}

.list_container::-webkit-scrollbar {
  width: 0.3rem;
}

.list_container::-webkit-scrollbar-track {
  border: 1px solid lightgrey;
  background-color: lightgrey;
}

.list_container::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: grey;
}

/* name_container */
.name_container {
  display: flex;
  max-width: 200px;

  flex-direction: column;
}

.name_container label {
  max-width: 100%;
  letter-spacing: 0;
  box-sizing: border-box;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  white-space: nowrap;
  overflow-wrap: any;
  text-overflow: ellipsis;
}

.primary {
  min-width: 70px;
  width: 70px;
  text-align: center;
}

.primary label {
  color: white;
  font-weight: 600;
  padding: 0.2rem 0.5rem;
  border-radius: 0.5rem;
  background-color: var(--main_color);
}

/* action_container */
.action_container {
  display: flex;

  min-width: 60px;
  align-items: flex-end;
}

.action_container {
  cursor: pointer;
}

/* input price */
.input_price {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
}

.range_price {
  white-space: nowrap;
  padding: 0.2rem 0.5rem;
  text-align: end;
}

.input_price input {
  min-width: 70px;
  border-radius: 0 0.5rem 0.5rem 0;
}

.input_price span {
  padding: 0.2rem;
  background-color: grey;
  border: 1px solid grey;
  border-radius: 0.5rem 0 0 0.5rem;
}

.input_container {
  flex: 4;
}

@media screen and (max-width: 500px) {
  .dtl_container {
    flex-direction: column;
    margin-bottom: 0.5rem;
  }

  .dtl_container:first-child {
    margin-top: 0.5rem;
  }

  .dtl_container_part1 {
    align-items: flex-start;
    justify-content: space-between;
  }
}
