/* header modal */
.modal_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid lightgrey;
  margin-bottom: 0.5rem;
}

.close_btn {
  cursor: pointer;
}

.title {
  color: var(--main_color);
  font-size: 1.2rem;
  font-weight: bold;
}
