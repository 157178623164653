.container {
  position: relative;
  display: inline-block;
}

.btn_container {
  width: 100px;
  margin-top: 0.5rem;
  cursor: pointer;
  padding: 0.1rem 0.5rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.5rem;
  outline: 1px solid lightgray;
  font-weight: 600;
}

.menu_container {
  list-style: none;
  position: absolute;
  z-index: 2;

  height: fit-content;
  margin-top: 0.3rem;
  padding: 0 0.5rem;

  font-weight: 600;
  border-radius: 0.5rem;
  box-shadow: 2px 2px 3px grey;
  background-color: white;
}

.menu_container li {
  padding: 0.2rem 0.5rem;
  cursor: pointer;
  border-radius: 0.5rem;
  white-space: nowrap;
}

.menu_container li:first-child {
  margin-top: 0.5rem;
}

.menu_container li:last-child {
  margin-bottom: 0.5rem;
}

.menu_container li:hover {
  color: white;
  background-color: var(--main_color);
}

.down,
.up {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.2s ease-in-out;
  -moz-transition: max-height 0.2s ease-in-out;
  -o-transition: max-height 0.2s ease-in-out;
  transition: max-height 0.2s ease-in-out;
}

.down {
  max-height: 500px;
  outline: 1px solid lightgrey;
}
