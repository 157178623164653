.form_container {
  width: 100%;
}

.form_container input {
  margin-bottom: 0.5rem;
}

.error_text {
  color: red;
  font-weight: 600;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
}

.otp_opt_container,
.opt_container {
  text-align: center;
}

.otp_opt_container > * {
  margin-bottom: 0.5rem;
}

.otp_opt_title {
  font-size: 1.1rem;
  font-weight: 600;
}

.otp_opt_subtitle {
  color: gray;
}

.otp_card {
  border: 1px solid black;
  border-radius: 0.5rem;
  padding: 0.5rem;
  cursor: pointer;
  box-shadow: 1px 1px 1px black;

  font-weight: 600;
}

.timer_container {
  margin-bottom: 0.5rem;
}

.opt_container .otp_opt_title {
  font-size: 0.9rem;
  margin-bottom: 0.25rem;
}

.opt_container input {
  text-align: center;
}

.re_call_otp {
  cursor: pointer;
  font-weight: 600;
}
