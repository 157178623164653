.body_container {
	padding: 0 5vw;
	padding-top: var(--def_padding_top);
}

.body_inner_container {
	padding-top: 2rem;
}

.body_part_container {
	padding: 0 5vw;
}

.page_title {
	color: var(--main_color);
	font-size: 2rem;
	font-weight: 600;
}

/* custom input */
.line_input_container {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 0.5rem;
}

.line_input_container input {
	padding: 0 !important;
	border: none !important;
	border-bottom: 1px solid lightgray !important;
	border-radius: 0 !important;
}

.line_input_container label {
	font-size: 0.8rem;
	font-weight: 700;
}

.line_input_container.disabled {
	color: gray;
}

/* phone input */
.group_input {
	display: flex;
	gap: 0.5rem;
}

.group_input button {
	width: fit-content;
}

/* error */
.error_txt {
	color: red !important;
	font-weight: 600;
}

/* scroll container */
.scroll_container::-webkit-scrollbar {
	width: 0.3rem;
}

.scroll_container::-webkit-scrollbar-track {
	border: 1px solid #ffffff;
	border-radius: 0 0.5rem 0.5rem 0;
	background-color: #ffffff;
}

.scroll_container::-webkit-scrollbar-thumb {
	border-radius: 0.5rem;
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: var(--main_color);
}

@media screen and (max-width: 400px) {
	.body_inner_container {
		padding-top: 0;
	}

	.body_part_container {
		padding: 0;
	}
}
