.container {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.container::-webkit-scrollbar {
  display: none;
}

.menu_container {
  display: flex;
  padding-top: 0.5rem;
  gap: 0.5rem;
}
.menu_container button {
  padding: 0.2rem;
}

/* referral */
.referal_form {
  padding: 0.5rem;
}

._form {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

._form > * {
  flex: 1 1 50%;
}

/*  */
.input_container {
  margin-bottom: 0.3rem;
}

.input_container label {
  margin-bottom: 0.1rem;
}

.input_container input:disabled {
  background-color: grey;
}

/*  */
.date_container {
  display: flex;
  align-items: center;
}

.date_container span {
  margin: 0 0.4rem;
}

.date_container input {
  width: 100%;
  border-radius: 0.3rem;
  border: 1px solid lightgray;
  padding: 0.2rem 0.4rem;
}

.date_container input:focus-visible {
  outline: none;
}

.date_container > div {
  width: fit-content;
}

/* */
.btn_container {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}

.btn_container button {
  max-width: 10rem;
  height: 2rem;
}

.unit_dropdown button {
  height: 100%;
  min-width: 55px;
}

.unit_dropdown > div {
  width: fit-content;
  min-width: 0;
  padding-left: 0.3rem;
}

/* fillter  */
.filter_container {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  padding: 0 0.5rem;
  gap: 0.5rem;
}

.filter_container input {
  width: 100%;
}

.filter_container button {
  display: flex;
  width: 140px;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.4rem;
  padding: 0.5rem ;
}

/* table */
.tbl_container {
  margin-top: 0.5rem;
}

.tbl_container > div::-webkit-scrollbar {
  height: 0.3rem;
}

.tbl_container > div::-webkit-scrollbar-track {
  border: 1px solid #ffffff;
  border-radius: 0 0.5rem 0.5rem 0;
  background-color: #ffffff;
}

.tbl_container > div::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--main_color);
}

.promo_code {
  font-weight: 600;
  color: var(--main_color);
  text-decoration: underline;
  cursor: pointer;
}

.promo_code:hover {
  color: var(--main_color);
}

.active_stat,
.non_active_stat {
  width: 5rem;
  padding: 0.2rem 0.5rem;
  border-radius: 0.4rem;
  color: white;
  text-align: center;
}

.active_stat {
  background-color: green;
}

.non_active_stat {
  background-color: red;
}

/* commission modal */
.com_tbl_info {
  text-align: center;
  width: 100%;
}

.com_tbl_info tr {
  vertical-align: top;
  border-bottom: 2px solid var(--main_color);
}

.com_tbl_info th {
  color: var(--main_color);
}

.com_tbl_info th,
.com_tbl_info td {
  padding: 0.5rem;
}

/* promotion setting */
.promo_info_container {
  /* color: ; */
  background-color: rgb(211, 211, 211, 0.3);
  border-radius: 0.5rem;
  padding: 0.5rem;
  font-size: 0.8rem;
  margin-top: 0.5rem;

  text-align: justify;
}

.psub_input {
  margin: 0.5rem 0;
}

.psub_input #subheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
}

.psub_input #title {
  color: var(--main_color);
  font-weight: 600;
}

.psub_input #count {
  font-size: 0.8rem;
}

.psub_input textarea {
  border-radius: 0.5rem;
  resize: none;
  width: 100%;
  height: 50vh;
  padding: 0.5rem;
  border: 2px solid lightgray;
}

.psub_input textarea:focus-visible {
  outline: none;
}

.psub_input textarea::-webkit-scrollbar {
  width: 0.2rem;
}

.psub_input textarea::-webkit-scrollbar-track {
  border: 1px solid #ffffff;
  border-radius: 0 0.5rem 0.5rem 0;
  background-color: #ffffff;
}

.psub_input textarea::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--main_color);
}

@media screen and (max-width: 400px) {
  ._form {
    flex-direction: column;
  }

  .menu_container {
    flex-direction: column;
    gap: 0.2rem;
  }

  .btn_container {
    margin-top: 0.5rem;
  }
}
