.container {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  padding-top: 0.5rem 0;
  position: relative;
}

.header {
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-bottom: 2px solid lightgray;
  gap: 0.5rem;
}

.filler_container {
  width: 100%;
  display: flex;
}

.header button {
  width: fit-content;
  height: fit-content;
  padding: 0.3rem 0.5rem;
  display: flex;
  gap: 0.5rem;

  span{
    white-space: nowrap;
  }
}

/* product list */
.outer_list_container {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.outer_list_container::-webkit-scrollbar {
  width: 0.3rem;
}

.outer_list_container::-webkit-scrollbar-track {
  border: 1px solid #ffffff;
  background-color: #ffffff;
}

.outer_list_container::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--main_color);
}

.list_container {
  list-style: none;
  padding: 0.5rem 0;
}

/* product card */
.card_container {
  padding: 0.2rem 0.5rem;
  border-bottom: 1px solid lightgrey;
  padding-bottom: 0.5rem;
}

.card_container:last-child {
  border: none;
  padding-bottom: 0;
}

/* dtl product */
.detail_container {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

#name {
  max-width: 100%;
  letter-spacing: 0;
  box-sizing: border-box;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  white-space: normal;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
}

.inner_dtl_container {
  display: flex;
  gap: 5px;
  width: 100%;
}

.name_container {
  flex: 4;
  margin-bottom: 0.2rem;
}

.price_container {
  flex: 5;
}

/* input container */
.input_container {
  flex: 5;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.input_container label {
  font-weight: 600;
  min-width: 40px;
}

.input_container input {
  min-width: 100px;
}

.inner_part_container {
  flex: 8;
  display: flex;
  gap: 0.5rem;
}

.input_price {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  min-width: 100px;
}

.range_price {
  /* white-space: nowrap; */
  padding: 0.2rem;
  min-width: 100px;
}

.input_price input {
  min-width: 100px;
  border-radius: 0 0.5rem 0.5rem 0;
}

.input_price span {
  padding: 0.2rem;
  background-color: lightgrey;
  border: 1px solid lightgray;
  border-radius: 0.5rem 0 0 0.5rem;
}

/* action_container */
.action_container {
  display: flex;
  align-items: flex-end;
  padding: 0.2rem;
  gap: 0.5rem;
}

.action_container i {
  font-size: 1.2rem;
  height: fit-content;
  cursor: pointer;
}

.cg_product_status {
  font-weight: 600;
  color: #ffffff;
  border-radius: 0.5rem;
  padding: 0.2rem 0.5rem;
}

@media screen and (max-width: 600px) {
  .container>* {
    font-size: 0.9rem;
  }

  .inner_dtl_container {
    flex-direction: column;
  }

  .inner_part_container {
    flex-direction: column;
    gap: 0.2rem;
  }

  .input_container {
    flex-direction: row;
    align-items: center;
  }
}