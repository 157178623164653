.container {
  padding: 0.5rem;
  height: 100%;
  overflow-y: scroll;
}

.container::-webkit-scrollbar,
.input_text_area textarea::-webkit-scrollbar {
  width: 0.3rem;
}

.container::-webkit-scrollbar-track,
.input_text_area textarea::-webkit-scrollbar-track {
  border: 1px solid #ffffff;
  border-radius: 0 0.5rem 0.5rem 0;
  background-color: #ffffff;
}

.container::-webkit-scrollbar-thumb,
.input_text_area textarea::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--main_color);
}

.part_container {
  display: flex;
  align-items: flex-start;
}

.part_container > * {
  min-width: 50%;
  width: 100%;
  padding: 0 0.5rem;
}

.small_part_container {
  display: flex;
  gap: 10%;
  justify-content: space-between;
}

.banner_container {
  margin-bottom: 0.5rem;
}

.btn_container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.btn_container button {
  max-width: 120px;
}

.group_title {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.title {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.2rem;
}

.count_text {
  font-size: 0.8rem;
}

.input_text_area {
  margin: 0.5rem 0;
}

.input_text_area #subheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
}

.input_text_area #title {
  color: var(--main_color);
  font-weight: 600;
}

.input_text_area #count {
  font-size: 0.8rem;
}

.input_text_area textarea {
  border-radius: 0.5rem;
  resize: none;
  width: 100%;
  min-height: 100px;
  padding: 0.5rem;
  border: 2px solid lightgray;
}

.input_text_area textarea:focus-visible {
  outline: none;
}

/* subdomain */
.subdomain_change_btn {
  cursor: pointer;
  font-weight: 600;
  color: var(--main_color);
}

.subdomain {
  padding: 0.2rem 0;
  font-weight: 600;
}

.note {
  color: grey;
  font-size: 0.8rem;
  margin-right: 0.5rem;
}

.change_subdomain_container {
  margin: 0.5rem 0;
}

@media screen and (max-width: 800px) {
  .part_container {
    gap: 0.5rem;
    flex-direction: column;
  }

  .btn_container button {
    max-width: none;
    width: 100%;
  }
}

@media screen and (max-width: 1100px) {
  .small_part_container {
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }
}
